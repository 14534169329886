import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ReleaseNote = makeShortcode("ReleaseNote");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <ReleaseNote date="2015-11-30" title="Expanded coverage of prepaid cards" mdxType="ReleaseNote">
      <p>{`We are pleased to announce expanded coverage of prepaid cards in minFraud. We
should return `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`prepaid=Yes`}</inlineCode>
        {` in minFraud premium and `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`is_prepaid=true`}</inlineCode>
        {` in minFraud
Insights for a larger range of prepaid cards.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2015-06-29" title="New beta versions of minFraud Score and minFraud Insights" mdxType="ReleaseNote">
      <p>{`We've released the beta versions of two new minFraud services, minFraud Score
and minFraud Inisghts.`}</p>
      <p>{`To use either service, you must upgrade to version 2.0 of the client API. Please
refer to the
`}
        <a {...{
          "href": "/minfraud/api-documentation",
          "parentName": "p"
        }}>{`minFraud Score and minFraud Insights API documentation`}</a>
        {`
for more details.`}</p>
      <p>{`Changes in version 2.0 of the minFraud APIs include:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`RESTful HTTP API`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Request data validation`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Model classes for return data`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`.NET client API now available`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Python client API now available`}</li>

      </ul>
      <p>{`minFraud version 2.0 provides the following `}
        <strong {...{
          "parentName": "p"
        }}>{`new inputs`}</strong>
        {`:`}</p>
      <p>{`In association with billing address:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`First name`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Last name`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Company`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Address line 1`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Address line 2`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Phone number`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Phone country code`}</li>

      </ul>
      <p>{`In association with shipping address:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`First name`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Last name`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Company`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Address line 2`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Phone number`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Phone country code`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Delivery speed`}</li>

      </ul>
      <p>{`User data:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Customer ID`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Hashed or unhashed email address`}</li>

      </ul>
      <p>{`Credit card information:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Last four digits`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Authorization outcome`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Decline reason`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Processor name`}</li>

      </ul>
      <p>{`Transaction information:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Transaction type`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Timestamp`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Discount code`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Affiliate ID`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Subaffiliate ID`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Referrer URI`}</li>

      </ul>
      <p>{`Shopping cart:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Item category`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Item ID or URL`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Item quantity`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Item price`}</li>

      </ul>
      <p>{`minFraud Insights provides the following `}
        <strong {...{
          "parentName": "p"
        }}>{`new outputs`}</strong>
        {` (note that minFraud
Score returns the riskScore only):`}</p>
      <p>{`All geolocation outputs provided by the
`}
        <a {...{
          "href": "https://www.maxmind.com/en/geoip2-precision-insights",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`GeoIP2 Insights web service`}</a>
        {`.`}</p>
      <p>{`Billing and shipping related outputs:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Billing is in IP country`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Shipping is in IP country`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Distance between IP and shipping address`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Billing address longitude`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Billing address latitude`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Shipping address longitude`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Shipping address latitude`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Billing address to shipping address distance`}</li>

      </ul>
      <p>{`Local date/time of user using IP`}</p>
      <p>{`ProxyScore is now IP risk score. (/ip_address/risk). Whereas the ProxyScore was
expressed on a scale of 0.00-4.00, the IP risk score uses a scale of 0.01 to 99.`}</p>
      <p>{`The following fields are deprecated in version 2.0:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Anonymous Proxy (anonymousProxy)`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Corporate Proxy (ip_corporateProxy)`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Free Email (freeMail)`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`High Risk Email (carderEmail)`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`High risk username and high risk password (highRiskUsername, highRiskPassword)`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Phone number check against billing postal code (custPhoneInBillingLoc)`}</li>

      </ul>
      <p>{`Country Match (countryMatch) has been replaced by `}
        <em {...{
          "parentName": "p"
        }}>{`billing country is in IP
country`}</em>
        {` and `}
        <em {...{
          "parentName": "p"
        }}>{`shipping country is in IP country`}</em></p>
    </ReleaseNote>
    <ReleaseNote date="2015-03-27" title="Discontinuing /app/ws_ipaddr" mdxType="ReleaseNote">
      <p>{`On April 28, we will deactivate the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/app/ws_ipaddr`}</inlineCode>
        {` endpoint. This endpoint
returns a list of IP addresses for use when making minFraud or GeoIP web
service requests to MaxMind servers.`}</p>
      <p>{`If you use this endpoint, you need to take action in order to continue accessing
MaxMind services. Please switch and make requests using the minfraud.maxmind.com
hostname for minFraud webservice requests and geoip.maxmind.com for GeoIP
webservice requests.`}</p>
      <p>{`If you use a minFraud client API we provided, please update to the latest API,
available on
`}
        <a {...{
          "href": "/minfraud/evaluate-a-transaction#links-to-maxmind-client-apis",
          "parentName": "p"
        }}>{`the client APIs page`}</a>
        {`.
Our newest API versions use hostnames instead of /app/ws_ipaddr.`}</p>
      <p>{`If you use a third-party plugin that makes requests to MaxMind web services, you
may need to update it.`}</p>
    </ReleaseNote>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      